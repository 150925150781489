import { Context, notDesktop, styled, Text, useMutation } from "@obvio/app";
import { SvgArrow2 } from "@obvio/svg";
import { FadeIn, RichText } from "@obvio/template";
import {
  Card,
  CheckboxInput,
  Form,
  FormPage,
  Grid,
  Stack,
  Submit,
  SubmitState,
  TextArea,
  TextInput,
} from "@obvio/ui";
import { useCallback } from "react";

import type { ReactElement } from "react";

const ContactCard = styled(Card)`
  background: ${(theme) => theme.colors.primary.dark};
  border-radius: ${(theme) => theme.radius.small};
  color: white;
  > div {
    padding: 1rem 5rem !important;
    height: 100%;
    > div {
      height: 100%;
    }
  }

  @media ${notDesktop} {
    > div {
      padding: 1rem !important;
    }
  }
  p {
    color: white;
  }
`;

const Wrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

const Arrow = styled(SvgArrow2)`
  background: ${(theme) => theme.colors.primary.dark};
  color: white;
  padding: 0.2rem;
  height: 2.3125rem;
  border-radius: 9999px;
  transform: rotate(180deg);
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

function Contact() {
  const [requestContact] = useMutation("contact");

  const handleSubmit = useCallback(
    (data: {
      name: string;
      email: string;
      phone?: string;
      dates?: string;
      message: string;
    }) => {
      void requestContact({
        name: data.name,
        email: data.email,
        phone: data.phone,
        dates: data.dates,
        message: data.message,
      });

      return SubmitState.OK;
    },
    [requestContact],
  );
  return (
    <Context name="green">
      <ContactCard>
        <Card.Section>
          <Form contextOnly onSubmit={handleSubmit}>
            <FormPage>
              <Stack kind="vertical" align="center">
                <TextInput name="name" label="Imię i Nazwisko" />
                <TextInput name="email" label="Adres e-mail" type="email" />
                <TextInput name="phone" label="Telefon" optional />
                <TextInput
                  name="dates"
                  label="Preferowane daty rezerwacji"
                  optional
                />
                <TextArea minRows={5} name="message" label="Treść wiadomości" />
                <CheckboxInput
                  required
                  name="rodo"
                  label="Wyrażam zgodę na gromadzenie i przetwarzanie moich danych osobowych zgodnie z RODO i Polityką Prywatności w celu uzyskania informacji o otwarciu strony internetowej. Wyrażam zgodę na kontakt mailowy lub telefoniczny."
                />
                <Submit kind="secondary">Wyślij wiadomość</Submit>
              </Stack>
            </FormPage>
            <FormPage successPage>
              <Center>
                <FadeIn>
                  <Text as="h3">Dziękujemy</Text>
                </FadeIn>
              </Center>
            </FormPage>
          </Form>
        </Card.Section>
      </ContactCard>
    </Context>
  );
}

const Title = styled(Text)`
  flex: 1;
`;

type FormContactProps = {
  title: string;
  description: string;
  description2: string;
  description3: string;
};

export function FormContact({
  title,
  description,
  description2,
  description3,
}: FormContactProps): ReactElement {
  return (
    <Wrap templateColumns="1fr 1.25fr" gap="extraLarge">
      <Stack kind="vertical" spacing="large" divider>
        <Stack kind="vertical" spacing="medium">
          <Title tag="h2">{title}</Title>
          <Arrow />
        </Stack>
        <div>
          <RichText value={description} />
        </div>
        <div>
          <RichText value={description2} />
        </div>
        <div>
          <RichText value={description3} />
        </div>
      </Stack>
      <Contact />
    </Wrap>
  );
}
