import { notDesktop, styled, Text, useMutation } from "@obvio/app";
import { FadeIn } from "@obvio/template";
import {
  CheckboxInput,
  Form,
  FormPage,
  Grid,
  GridItem,
  Stack,
  Submit,
  SubmitState,
  TextInput,
} from "@obvio/ui";
import { useCallback } from "react";

import type { ReactElement } from "react";

const FormWrap = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 1rem;
  button {
    position: absolute;
    right: 0;
  }
`;

const GridWrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

export function FormNewsletter(): ReactElement {
  const [requestNewsletter] = useMutation("registerNewsletter");

  const handleSubmit = useCallback(
    (data: { mail: string }) => {
      void requestNewsletter({
        email: data.mail,
      });

      return SubmitState.OK;
    },
    [requestNewsletter],
  );

  return (
    <GridWrap templateColumns="1.25fr 1fr" gap="extraLarge">
      <Stack kind="vertical" spacing="big">
        <Text tag="h2">
          Zapisz się do newslettera i bądź na bieżąco z nowościami
        </Text>
        <Form contextOnly onSubmit={handleSubmit}>
          <FormPage>
            <FormWrap>
              <TextInput name="mail" label="Adres e-mail" />
              <Submit>Zapisz mnie</Submit>
            </FormWrap>
            <CheckboxInput
              required
              name="rodo"
              label="Wyrażam zgodę na przetwarzanie danych osobowych i kontakt za pośrednictwem poczty elektronicznej. Sprawdź naszą Politykę Prywatności aby dowiedzieć się w jaki sposób przetwarzamy dane. W dowolnym momencie możesz wycofać zgody."
            />
          </FormPage>
          <FormPage successPage>
            <Center>
              <FadeIn>
                <Text as="h3">Dziękujemy</Text>
              </FadeIn>
            </Center>
          </FormPage>
        </Form>
      </Stack>
      <GridItem>
        <Text>
          W Pałacu Mała Wieś nieustanie dzieją się rzeczy niezwykłe.
          <br />
          Zapisz się do newslettera i bądź na bieżąco z nowościami.
        </Text>
      </GridItem>
    </GridWrap>
  );
}
